import React, { useState } from 'react'

import { SpringFadeUp, TrailFadeUp } from '@utils/animations'
import {
  StyledMasonry,
  ProjectSuperscription,
  ProjectTitle,
  ProjectImage,
  ProjectLink,
  DateBox,
  ProjectSummary,
  ProjectLinkInner,
  SectionTitle,
} from './style'
import { Flex } from '@rebass/grid'
import { PrismicNode } from '@models/archive.models'
import { RichText } from 'prismic-reactjs'
import { ContainerBox } from '@components/container'
import { linkResolver } from '@prismic/linkResolverHelper'
import { LargeContainerBox } from '@components/container/container'

export type ArticleListPrimaryProps = {
  posts: any
  loadMoreLabel?: string
  showDate: boolean
  title: string
  id?: string
}

export const ArticleListNewSlice: React.FC<ArticleListPrimaryProps> = ({
  posts,
  loadMoreLabel,
  showDate = true,
  title,
  id
}) => {
  // TODO move chunkSize to prismic and set 3 as default/fallback value
  const chunkSize = 6
  const [renderedPosts, setVisiblePosts] = useState<PrismicNode<any>[]>(
    posts?.slice(0, Math.min(posts.length, chunkSize)) || [],
  )
  const loadMore = () => {
    setVisiblePosts(
      posts.slice(0, Math.min(posts.length, renderedPosts.length + chunkSize)),
    )
  }
  return (
    <LargeContainerBox outerAttributes={{ id: id }}>
      <SpringFadeUp>
        <SectionTitle
          // style={{ fontSize: '61px', marginBottom: '48px' }}
          className="u-text-decoration-underlined"
        >
          {title}
        </SectionTitle>
      </SpringFadeUp>

      <StyledMasonry
        className="c-masonry-grid"
        columnsCount={1}
        gutter={'9rem'}
      >
        {renderedPosts?.map((post, index) => {
          const node = post?.node
          const nodeData = node?.data
          const cover = nodeData?.feature_image
          return (
            node && (
              <ProjectLink
                key={`article-item-${index}`}
                to={node?.url}
                className="c-masonry-grid__item"
              >
                <Flex className="c-masonry-grid__conent" flexWrap="wrap">
                  {cover?.gatsbyImageData && (
                    <SpringFadeUp delay={(index % 3) * 250}>
                      <ProjectLinkInner className="c-masonry-grid__image">
                        <ProjectImage
                          image={cover?.gatsbyImageData}
                          alt={cover?.alt || 'cover image'}
                        />
                      </ProjectLinkInner>
                    </SpringFadeUp>
                  )}
                  <ProjectSummary className="c-masonry-grid__text">
                    <SpringFadeUp delay={800}>
                      {showDate && <DateBox>{nodeData?.date}</DateBox>}
                      <ProjectTitle>{nodeData?.post_title?.text}</ProjectTitle>
                      <ProjectSuperscription>
                        <RichText
                          linkResolver={linkResolver}
                          render={nodeData.superscription.richText}
                        />
                      </ProjectSuperscription>
                    </SpringFadeUp>
                  </ProjectSummary>
                </Flex>
              </ProjectLink>
            )
          )
        })}
      </StyledMasonry>
      {posts.length !== renderedPosts.length && (
        <button
          className="c-button c-button_secondary c-button_center"
          onClick={loadMore}
        >
          {loadMoreLabel}
        </button>
      )}
    </LargeContainerBox>
  )
}
