import React, { useRef } from 'react'
import { Sticky2Styled } from './style'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'
import { SpringFadeUp } from '@utils/animations'
import { Crossfade } from '@components/cross-fade/cross-fade'

export const Hero: React.FC<any> = (props: any) => {
  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()
  const Sticky2 = () => (
    <Sticky2Styled>
      {/* <SplitChars></SplitChars> */}
      <Controller>
        <div>
          <Scene triggerHook="onLeave" duration={400} pin>
            {(progress: any) => (
              <div className="sticky">
                <Timeline totalProgress={progress} paused>
                  {/* <div className="heading">
                    <h1>
                      <Timeline target={<div>light</div>}>
                        <Tween
                          duration={1500}
                          from={{ opacity: 0 }}
                          to={{ x: '-120%', opacity: 1 }}
                        />
                        <Tween to={{ x: '-240%', opacity: 0 }} duration={500} />

                        <Timeline target={<div>motion</div>}>
                          <Tween
                            duration={1500}
                            from={{ opacity: 0 }}
                            to={{ x: '-120%', opacity: 1 }}
                          />
                          <Tween
                            to={{ x: '-240%', opacity: 0 }}
                            duration={500}
                          />
                        </Timeline>

                        <Timeline target={<span>photo paper</span>}>
                          <Tween
                            duration={1500}
                            from={{ opacity: 0 }}
                            to={{ x: '-120%', opacity: 1 }}
                          />
                          <Tween
                            to={{ x: '-240%', opacity: 0 }}
                            duration={500}
                          />
                        </Timeline>

                        {/* <Timeline target={<span>8 sec exposure</span>}>
                        <Tween
                          duration={1500}
                          from={{ opacity: 0 }}
                          to={{ x: '-120%', opacity: 1 }}
                        />
                        <Tween to={{ x: '-240%', opacity: 0 }} duration={500} />
                      </Timeline>
                      {/* </Timeline>
                    {/* </h1>
                  </div> */}
                  <div className="hero-skip">
                    <SpringFadeUp delay={1000}>
                      <Tween to={{ opacity: 0 }} duration={300}>
                        <button
                          onClick={executeScroll}
                          className="c-button c-button_secondary c-button_small c-fullscreen-video__go-forward"
                        >
                          Scroll down
                        </button>
                      </Tween>
                    </SpringFadeUp>
                  </div>
                  <SpringFadeUp delay={500}>
                    <Tween to={{ width: '100vw' }} duration={1000}>
                      <div className="animation">
                        <Crossfade
                          images={[
                            'img/mcorg/hero/004. The_Moving_Camera_berlin_maciej_markowicz_ubermut_2Boats_phototrienniale001_2.jpg',
                            'img/mcorg/hero/005. Moving_Camera_Team_at Work 800x1200.jpg',
                            'img/mcorg/hero/001. 2019_10_KinderKulturMonat_Moving_Camera_art_education_workshop.015.jpg',
                            'img/mcorg/hero/002. boat tour inside Camera Obscura boat.jpg',
                            'img/mcorg/hero/003. The_Moving_Camera_gug_berlin_maciej_markowicz_006.jpg',
                            'img/mcorg/hero/006. SCAN0762.jpg',
                          ]}
                          interval={3000}
                          width={'100%'}
                        />
                      </div>
                    </Tween>
                  </SpringFadeUp>
                </Timeline>
              </div>
            )}
          </Scene>
        </div>
      </Controller>
    </Sticky2Styled>
  )

  document.documentElement.style.setProperty('--header-text-color', '#0F0F0F')
  document.documentElement.style.setProperty(
    '--header-text-color-revert',
    '#ffffff',
  )
  document.documentElement.style.setProperty(
    '--header-text-color-revert-alpha',
    '#ffffff20',
  )

  //  const {}
  return (
    <>
      <Sticky2 />
      <div ref={myRef}></div>

      {/* <StyledHeroOuter>
        <StyledHeroInner>
          <img
            height="800px"
            src={`img/hero1.jpg`}
            alt="light motion photo paper 8 sec exposure"
          />
        </StyledHeroInner>
      </StyledHeroOuter> */}
    </>
  )
}

// export const query = graphql``
