import styled from '@emotion/styled'
import { breakpoint } from '@styles/02-tools/tools.breakpoints';
import { colors } from '@styles/colors';
import { Link } from 'gatsby'


export const StyledLink = styled(Link)`
  text-decoration: underline;
    font-size: 1.5rem;



  svg {
    fill: #FF671D;;
    height: 2rem;
    vertical-align: middle;
    margin-left: 0.75rem;
  }

  &.has-margin-top {
    display: block;
    margin-top: 2rem;
  }

  :hover {
      text-decoration: none;

    svg {
      transform: translateX(10px);
      transition: 300ms;
    }
  }

      ${breakpoint.gt('md')`
  font-size: 2rem;



  svg {
    height: 1.5rem;
  }

`}
`;
