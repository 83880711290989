import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Hero } from '@components/hero/hero'
import { Layout } from '@components/layout'
import { ContainerBox, ContainerFlex } from '@components/container'
import { SpringFadeUp, TrailFadeUp } from '@utils/animations'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { SmallContainerFlex } from '@components/container/container'
import { Button } from '@components/button'
import { ArticleListNewSlice } from '@slices/article-list-new/article-list'
import ContentWithImageSlice from '@slices/content-with-image'
import { SectionTitle } from '@slices/article-list-new/style'
import ContentSlice from '@slices/content'
import {
  contentWithImageData1,
  contentWithImageData2,
  contentWithImageData3,
  contentWithImageData4,
  contentWithImageData5,
  contentWithImageData6,
} from 'src/data/home-data'

type Props = {
  data: any
  pageContext: any
}

const Index: React.FC<Props> = props => {
  const { meta, header, seo, main, footer } = props.data
  const works = props?.data?.works
  const news = props?.data?.news

  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      // theme={theme}
      activeMeta={meta}
    >
      <Hero />
      <ContainerFlex className="homep-text2wrapper">
        <h2 className="homep-text2">
          <SpringFadeUp>Moving Camera is</SpringFadeUp>
          <SpringFadeUp delay={400}>
            <span className="u-text-decoration-underlined">
              non-profit organization
            </span>
          </SpringFadeUp>
          <SpringFadeUp delay={400}>
            <span className="u-text-decoration-underlined">teaching</span>
          </SpringFadeUp>
          <SpringFadeUp delay={600}>children manual creativity</SpringFadeUp>
          <div className="second-line">
            <SpringFadeUp delay={700}>trough &nbsp;</SpringFadeUp>
            <SpringFadeUp delay={900}>
              <span className="last u-text-decoration-underlined">
                Art and Photography
              </span>
              {/* <span className="u-text-decoration-underlined">paper</span> */}
            </SpringFadeUp>
          </div>
        </h2>
      </ContainerFlex>

      <ContainerBox>
        <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 992: 2 }}>
          <Masonry columnsCount={2} gutter={'5rem'}>
            <SpringFadeUp>
              <figure>
                <img
                  loading="lazy"
                  src="img/mcorg/2019_10_KinderKulturMonat_Moving_Camera_art_education_workshop.015.jpg"
                  alt="A group children visiting a floating camera during the Kinder Kultur Monat, Kreuzberg, Berlin October, 2019"
                />
                <figcaption>
                  A group children visiting a floating camera during the Kinder
                  Kultur Monat, Kreuzberg, Berlin October, 2019
                </figcaption>
              </figure>
            </SpringFadeUp>
            <SpringFadeUp delay={200}>
              <figure>
                <img
                  loading="lazy"
                  src="img/mcorg/moving_camera_org_berlin_gemeinnutzig_gug_art_photography.jpeg"
                  alt="River Spree Boat Tour inside Camera Obscura boat: Museumsinsel - The Haus der Kulturen der Welt. September 2020"
                />
                <figcaption>
                  River Spree Boat Tour inside Camera Obscura boat: Museumsinsel
                  - The Haus der Kulturen der Welt. September 2020
                </figcaption>
              </figure>
            </SpringFadeUp>
            <SpringFadeUp delay={300}>
              <figure>
                <img
                  loading="lazy"
                  src="img/mcorg/moving_camera_obscura_nonprofit-berlin_08.jpg"
                  alt="Boat tour during Junior Workshop: Cityscapes - from water and on land  in cooperation with C/O BERLIN 30 Oct and 31 Oct, 2021 Location:  Museumsinsel, Schloss Bellevue"
                />
                <figcaption>
                  Boat tour during Junior Workshop: Cityscapes - from water and
                  on land in cooperation with C/O BERLIN 30 Oct and 31 Oct, 2021{' '}
                  <br />
                  Location: Museumsinsel, Schloss Bellevue
                </figcaption>
              </figure>
            </SpringFadeUp>
            <SpringFadeUp delay={400}>
              <figure>
                <img
                  loading="lazy"
                  src="img/mcorg/IMG_3573.jpeg"
                  alt="Paper negative of Reichstag Building hanging
                  inside the Floating Camera, River Spree, Berlin. Photo: The
                  Moving Camera Diary: Maciej Markowicz."
                />
                <figcaption>
                  {
                    'Sunset River Spree Boat Tour during Berlin Art Week, Jannowitzbrücke <-> Schloss Bellevue'
                  }{' '}
                  Saturday, September 18th, 2022
                </figcaption>
              </figure>
            </SpringFadeUp>
          </Masonry>
        </ResponsiveMasonry>
      </ContainerBox>

      <SmallContainerFlex>
        <blockquote>
          <TrailFadeUp>
            <p>
              We organize creative workshops and boat tours inside the Camera
              Obscura boat for children and young adults mostly from challenged
              backgrounds.
            </p>
            <Button
              linkTo={'#workshops'}
              linkTitle={'Sign up for next boat tour'}
              class="has-margin-top"
            />
          </TrailFadeUp>
        </blockquote>
      </SmallContainerFlex>

      <SmallContainerFlex>
        <SpringFadeUp className="u-fullwidth">
          <div className="c-content-video">
            <iframe
              loading="lazy"
              className="c-content-video__iframe"
              src="https://player.vimeo.com/video/763782850?background=1&autoplay=1&muted=1&loop=1"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay"
            ></iframe>
          </div>
        </SpringFadeUp>
      </SmallContainerFlex>

      <ContentWithImageSlice
        key={'contentWithImageData1'}
        props={contentWithImageData1}
        title="Our mission"
      />

      <ContentWithImageSlice
        key={'contentWithImageData2'}
        props={contentWithImageData2}
      />

      {/* <ContainerBox className="has_undeline">
        <SpringFadeUp>
          <SectionTitle
            // style={{ fontSize: '61px', marginBottom: '48px' }}
            className="u-text-decoration-underlined"
          ></SectionTitle>
        </SpringFadeUp>
      </ContainerBox> */}

      <ArticleListNewSlice
        id="workshops"
        showDate={false}
        posts={news.edges.map(post => post).slice(0, 3)}
        title="Upcoming Workshops"
      ></ArticleListNewSlice>

      <ArticleListNewSlice
        showDate={false}
        posts={works.edges.map(post => post).slice(0, 3)}
        title="Last Workshops"
      ></ArticleListNewSlice>

      <ContentWithImageSlice
        key={'contentWithImageData3'}
        props={contentWithImageData3}
        title="Meet founder & team"
      />
      <ContentWithImageSlice
        key={'contentWithImageData4'}
        props={contentWithImageData4}
      />

      <ContainerBox className="">
        <blockquote className="blockquote-wider">
          <TrailFadeUp>
            <p>
              Moving Camera believes in a flexible, inclusive, empowering, and
              non-discriminating work environment, recognizing and giving credit
              to the work and talent of each of its collaborators.
            </p>
            <Button
              linkTo={'about-us'}
              linkTitle={'meet the team'}
              class="has-margin-top"
            />
          </TrailFadeUp>
        </blockquote>
      </ContainerBox>
    </Layout>
  )
}

export default withPrismicPreview(Index)

export const pageQuery = graphql`
  query IndexQuery($locale: String) {
    meta: prismicHomepage(lang: { eq: $locale }) {
      lang
      type
      alternate_languages {
        uid
        lang
      }
    }
    seo: prismicHomepage(lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }

    header: prismicMenu(lang: { eq: $locale }) {
      ...header
    }

    main: prismicHomepage(lang: { eq: $locale }) {
      data {
        page_title {
          text
        }
      }
    }

    works: allPrismicWorkshopsPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          url
          data {
            date(formatString: "MMMM YYYY")
            feature_image {
              gatsbyImageData
              alt
              fluid {
                aspectRatio
              }
            }
            post_title {
              text
              richText
            }
            superscription {
              text
              richText
            }
          }
        }
      }
    }

    news: allPrismicNewsPost(
      sort: { fields: [data___date], order: ASC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          url
          data {
            date(formatString: "MMMM YYYY")
            feature_image {
              gatsbyImageData
              alt
            }
            post_title {
              text
            }
            superscription {
              richText
            }
          }
        }
      }
    }

    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
