import { graphql } from 'gatsby'
import React from 'react'
import { Background, BackgroundBackground, Container } from './style'

export const Crossfade: React.FC<any> = (props: any) => {
  const containerDimensions = {
    height: props.height ? props.height : '100vh',
    width: props.width ? props.width : '100vw',
  }
  const backgroundImages = props.images || []
  const carouselLength = backgroundImages.length
  const [currentCarouselIndex, setCarouselIndex] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCarouselIndex(currentCarouselIndex => {
        const newIndex =
          currentCarouselIndex + 1 >= carouselLength
            ? 0
            : currentCarouselIndex + 1
        return newIndex
      })
    }, props.interval || 5000)
    return () => clearInterval(interval)
  }, [currentCarouselIndex])

  const carousel = backgroundImages.map((element, index) => {
    return (
      // <img
      <Background
        key={index}
        className={currentCarouselIndex === index ? 'active' : 'inactive'}
        src={element}
        transitionDelay={
          props.transition ? `${props.transition / 1000}s` : '3s'
        }
      />
    )
  })

  return (
    <Container
      height={containerDimensions.height}
      width={containerDimensions.width}
    >
      <BackgroundBackground xRate={props.xRate} yRate={props.yRate}>
        {carousel}
      </BackgroundBackground>
    </Container>
  )
}

